import React, {useState, useEffect, Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { appTheme } from 'common/src/theme/app';
import {
  GlobalStyle,
  AppWrapper, 
  ConditionWrapper
} from '../containers/App/app.style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/App/Navbar';
import Footer from '../containers/App/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import styled from 'styled-components';
import { ResetCSS } from 'common/src/assets/css/style';
import SEO from '../components/seo';

// Chatbot container  style
const Privacy = styled.div`
  padding: 25px;
  font-family: roboto, sans-serif;   
`;

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

export default () => {   
  const size = process.browser && useWindowSize();
  const innerWidth = process.browser && size.innerWidth;
  

  return (
    <ThemeProvider theme={appTheme}>
      <Fragment>
        <SEO title="LuzIA | Privacidad" />
         
        <ResetCSS />
        <GlobalStyle />
        <AppWrapper>
        <ConditionWrapper id="topBar">
            {innerWidth > 1100 ? 
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            : <div />}
          </ConditionWrapper>
          <Privacy>
        <h2>Política de Privacidad</h2>
        <p>Esta política de privacidad establece la forma en que se van a gestionar los datos personales en esta web. Es imprescindible que la leas y aceptes antes de seguir navegando. <br/><br/>
LuzIA  es un proyecto de Luzia Sistemas Conversacionales SL y su CIF: ESB95990321.<br/><br/>
Entre los valores con los que nos identificamos está la transparencia, por eso deseamos que conozcas desde el inicio, que la privacidad de tus datos es muy importante para nosotros. En esta declaración de privacidad se explica qué datos personales recopilamos de nuestros usuarios y cómo los utilizamos. Te invitamos a leer detenidamente estos términos antes de facilitar tus datos personales en esta web. Los mayores de trece años podrán registrarse como usuarios en Luzia sin el previo consentimiento de sus padres o tutores.<br/><br/>
En el caso de los menores de trece años se requiere el consentimiento de los padres o tutores para el tratamiento de sus datos personales.<br/><br/>
En ningún caso se recabarán del menor de edad datos relativos a la situación profesional, económica o a la intimidad de los otros miembros de la familia, sin el consentimiento de éstos.<br/><br/>
Si eres menor de trece años y has accedido a este sitio web sin avisar a tus padres no debes registrarte como usuario.<br/><br/>
En esta web se respetan y cuidan los datos personales de los usuarios. Como usuario debes saber que tus derechos están garantizados.<br/><br/>
Nos hemos esforzado en crear un espacio seguro y confiable y por eso queremos compartir nuestros principios respecto a tu privacidad:<br/><br/>
<ul>
  <li style={{ 'list-style-type': 'disc' }} >
  Nunca solicitamos información personal a menos que realmente sea necesaria para prestar los servicios que nos requieras.          
  </li>
  <li style={{ 'list-style-type': 'disc' }} >
  Nunca compartimos información personal de nuestros usuarios con nadie, excepto para cumplir con la ley o en caso que contemos con tu autorización expresa.           
  </li>
  <li style={{ 'list-style-type': 'disc' }} >Nunca utilizaremos tus datos personales con una finalidad diferente a la expresada en esta política de privacidad.</li>
</ul>
</p>  
<p>Es preciso advertir que esta Política de Privacidad podría variar en función de exigencias legislativas o de autorregulación, por lo que se aconseja a los usuarios que la visiten periódicamente. Será aplicable en caso de que los usuarios decidan rellenar algún formulario de cualquiera de sus formularios de contacto donde se recaben datos de carácter personal.<br/><br/>
LuzIA  ha adecuado esta web a las exigencias de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), y al Real Decreto 1720/2007, de 21 de diciembre, conocido como el Reglamento de desarrollo de la LOPD. Cumple también con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas (RGPD), así como con la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE o LSSI).<br/><br/>
</p>
<h2>Responsable del tratamiento de tus datos personales</h2>
<ul>
<li style={{ 'list-style-type': 'disc' }} >Identidad del Responsable: Luzia Sistemas Conversacionales SL</li>
<li style={{ 'list-style-type': 'disc' }} >Nombre comercial: LuzIA</li>
<li style={{ 'list-style-type': 'disc' }} >NIF/CIF: ESB95990321</li>
<li style={{ 'list-style-type': 'disc' }} >Dirección: Alameda Areiltza Doktorearen 2, 48011 (Bilbao)</li>
<li style={{ 'list-style-type': 'disc' }} >Correo electrónico: info@luzia.chat</li>
<li style={{ 'list-style-type': 'disc' }} >Actividad: Coaching de vida, Ayuda personal</li>
</ul>
<p>
A efectos de lo previsto en el Reglamento General de Protección de Datos antes citado, los datos personales que nos envíes a través de la App y los formularios de la web, recibirán el tratamiento de datos de “Usuarios de la web y suscriptores”.<br/><br/>
Para el tratamiento de datos de nuestros usuarios, implementamos todas las medidas técnicas y organizativas de seguridad establecidas en la legislación vigente.
</p>

<h2>Principios que aplicaremos a tu información personal
</h2>
<p>En el tratamiento de tus datos personales, aplicaremos los siguientes principios que se ajustan a las exigencias del nuevo reglamento europeo de protección de datos:
</p>
<ul>
<li style={{ 'list-style-type': 'disc' }} ><b>Principio de licitud, lealtad y transparencia:</b> Siempre vamos a requerir tu consentimiento para el tratamiento de tus datos personales para uno o varios fines específicos que te informaremos previamente con absoluta transparencia.</li>
<li style={{ 'list-style-type': 'disc' }} ><b>Principio de minimización de datos:</b> Solo vamos a solicitar datos estrictamente necesarios en relación con los fines para los que los requerimos. Los mínimos posibles.</li>
<li style={{ 'list-style-type': 'disc' }} ><b>Principio de limitación del plazo de conservación:</b> los datos serán mantenidos durante no más tiempo del necesario para los fines del tratamiento, en función a la finalidad, te informaremos del plazo de conservación correspondiente, en el caso de suscripciones, periódicamente revisaremos nuestras listas y eliminaremos aquellos registros inactivos durante un tiempo considerable.</li>
<li style={{ 'list-style-type': 'disc' }} ><b>Principio de integridad y confidencialidad:</b> Tus datos serán tratados de tal manera que se garantice una seguridad adecuada de los datos personales y se garantice confidencialidad. Debes saber que tomamos todas las precauciones necesarias para evitar el acceso no autorizado o uso indebido de los datos de nuestros usuarios por parte de terceros.</li>
</ul>
<h2>¿Cómo hemos obtenido tus datos?</h2>
<ul>
  <p>Los datos personales que tratamos en LuzIA  proceden de:</p>
  <li style={{ 'list-style-type': 'disc' }} >Formulario de inscripción</li>
  <li style={{ 'list-style-type': 'disc' }} >Comentarios en blog</li>
  <li style={{ 'list-style-type': 'disc' }} >Formulario de venta</li>
</ul>
<h2>¿Cuáles son tus derechos cuando nos facilitas tus datos?</h2>
<p>Las personas interesadas tienen derecho a:
</p>
<ul>
<li style={{ 'list-style-type': 'disc' }} >Solicitar el acceso a los datos personales relativos al interesado</li>
<li style={{ 'list-style-type': 'disc' }} >Solicitar su rectificación o supresión</li>
<li style={{ 'list-style-type': 'disc' }} >Solicitar la limitación de su tratamiento</li>
<li style={{ 'list-style-type': 'disc' }} >Oponerse al tratamiento</li>
<li style={{ 'list-style-type': 'disc' }} >Solicitar la portabilidad de los datos</li>
</ul>
<p>Los interesados podrán <b>acceder</b> a sus datos personales, así como a solicitar la <b>rectificación</b> de los datos inexactos o, en su caso, solicitar su <b>supresión</b> cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. En determinadas circunstancias, los interesados podrán solicitar la <b>limitación</b> del tratamiento de sus datos, en cuyo caso únicamente los conservaré para el ejercicio o la defensa de reclamaciones.</p>
<p>En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán <b>oponerse</b> al tratamiento de sus datos.  LuzIA dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones. Cómo interesado, tienes derecho a recibir los datos personales que te incumban, que nos hayas facilitado y en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro responsable del tratamiento cuando:
</p>
<ul>
<li style={{ 'list-style-type': 'disc' }} >El tratamiento esté basado en el consentimiento</li>
<li style={{ 'list-style-type': 'disc' }} >Los datos hayan sido facilitados por la persona interesada.</li>
<li style={{ 'list-style-type': 'disc' }} >El tratamiento se efectúe por medios automatizados.</li>
</ul>
<p>Al ejercer tu derecho a la portabilidad de los datos, tendrás derecho a que los datos personales se transmitan directamente de responsable a responsable cuando sea técnicamente posible.</p>
<p>Los interesados también tendrán derecho a la tutela judicial efectiva y a presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si consideran que el tratamiento de datos personales que le conciernen infringe el Reglamento.
</p>
<h2>¿Con qué finalidad tratamos tus datos personales?</h2>
<p>Cuando un usuario se conecta con esta web por ejemplo para comentar un post, mandar un correo al titular, suscribirse o realizar alguna contratación, está facilitando información de carácter personal de la que es responsable Luzia Sistemas Conversacionales SL. Esa información puede incluir datos de carácter personal como pueden ser tu dirección IP, nombre, dirección física, dirección de correo electrónico, número de teléfono, y otra información. Al facilitar esta información, el usuario da su consentimiento para que su información sea recopilada, utilizada, gestionada y almacenada por LuzIA, sólo como se describe en el Aviso Legal y en la presente Política de Privacidad.</p>
<p>En LuzIA existen diferentes sistemas de captura de información personal y trato la información que nos facilitan las personas interesadas con el siguiente fin por cada sistema de captura (formularios):</p>
<ul>
<li style={{ 'list-style-type': 'disc' }} ><b>Formulario de contacto:</b> Solicitamos los siguientes datos personales: Nombre, Email, para responder a los requerimientos de los usuarios de LuzIA. Por ejemplo, podemos utilizar esos datos para responder a tu solicitud y dar respuesta a las dudas, quejas, comentarios o inquietudes que puedas tener relativas a la información incluida en la web, los servicios que se prestan a través de la web, el tratamiento de sus datos personales, cuestiones referentes a los textos legales incluidos en la web, así como cualesquiera otras consultas que puedas tener y que no estén sujetas a las condiciones de contratación. Te informo que los datos que nos facilitas estarán ubicados en los servidores de CloudWays  (proveedor de Luzia Sistemas Conversacionales SL) dentro de la UE.</li>
<li style={{ 'list-style-type': 'disc' }} ><b>Formulario de suscripción a contenidos:</b> En este caso, solicitamos los siguientes datos personales: Nombre, Email, para gestionar la lista de suscripciones, enviar boletines, promociones y ofertas especiales, facilitados por el usuario al realizar la suscripción. Dentro de la web existen varios formularios para activar la suscripción.</li>
<li style={{ 'list-style-type': 'disc' }} ><b>Formulario de venta:</b> El usuario dispone de diferentes formularios de compra sujetos a las condiciones de contratación especificadas en para cada producto o servicio donde se le requerirán datos de contacto y de pago como: Nombre, apellidos, DNI, correo electrónico, teléfono, dirección completa. Los datos de pago serán recabados y gestionados por la pasarela de pagos utilizados por la web.</li>
</ul>
<p>
Existen otras finalidades por la que tratamos tus datos personales:
</p>
<ul>
<li style={{ 'list-style-type': 'disc' }} ><b>Para garantizar el cumplimiento de las condiciones de uso y la ley aplicable..</b> Esto puede incluir el desarrollo de herramientas y algoritmos que ayudan a esta web a garantizar la confidencialidad de los datos personales que recoge.</li>
<li style={{ 'list-style-type': 'disc' }} ><b>Para apoyar y mejorar los servicios que ofrece esta web.</b></li>
<li style={{ 'list-style-type': 'disc' }} >También se recogen otros datos no identificativos que se obtienen mediante algunas cookies que se descargan en el ordenador del usuario cuando navega en esta web que detallo en la política de cookies.</li>
<li style={{ 'list-style-type': 'disc' }} ><b>Para gestionar las redes sociales.</b> LuzIA puede tener presencia en redes sociales. El tratamiento de los datos que se lleve a cabo de las personas que se hagan seguidoras en las redes sociales de las páginas oficiales de LuzIA se regirá por este apartado. Así como por aquellas condiciones de uso, políticas de privacidad y normativas de acceso que pertenezcan a la red social que proceda en cada caso y aceptadas previamente por el usuario de LuzIA. Tratará sus datos con la finalidad de administrar correctamente su presencia en la red social, informando de actividades, productos o servicios de Luzia Sistemas Conversacionales SL. Así como para cualquier otra finalidad que las normativas de las redes sociales permitan. En ningún caso utilizaremos los perfiles de seguidores en redes sociales para enviar publicidad de manera individual.
</li>
</ul>
<p>De acuerdo a lo establecido en el reglamento general de protección de datos europeo (RGPD) 2016/679, Luzia Sistemas Conversacionales SL  con domicilio en Alameda Areiltza Doktorearen 2, 48011 (Bilbao) será responsable del tratamiento de los datos correspondientes a Usuarios de la web y suscriptores.</p>
<p>LuzIA, no vende, alquila ni cede datos de carácter personal que puedan identificar al usuario, ni lo hará en el futuro, a terceros sin el consentimiento previo. Sin embargo, en algunos casos se pueden realizar colaboraciones con otros profesionales, en esos casos, se requerirá consentimiento a los usuarios informando sobre la identidad del colaborador y la finalidad de la colaboración. Siempre se realizará con los más estrictos estándares de seguridad.</p>          

<h2>Remarketing</h2>
<p>La función de remarketing nos permite llegar a las personas que hayan visitado LuzIA  anteriormente y asociar una audiencia determinada a un mensaje concreto. El remarketing es un método para conseguir que los usuarios que han visitado nuestro sitio vuelvan a hacerlo.
</p>
<p>Como usuario de LuzIA te informo que estoy recopilando información para esta función de remarketing en LuzIA.</p>
<p>La información que recopilamos gracias a esta función es recogida por las cookies de Facebook. Puedes conocer los datos que recopilan estas cookies en las siguientes políticas de privacidad de cada servicio:</p>
<p><b>Facebook</b></p>
<p>Si no quieres que tu información sea recopilada por estas cookies, puedes inhabilitar el uso de cookies de Google a través de la Configuración de anuncios de Google. También puedes inhabilitar el uso de cookies de un proveedor tercero a través de la página de inhabilitación de Network Advertising Initiative.</p>
<p>Este tipo de servicios permite interactuar con redes sociales u otras plataformas externas directamente desde las páginas de esta web. Las interacciones y la información obtenida por esta web siempre estarán sometidas a la configuración de privacidad del usuario en cada red social. En caso de que se instale un servicio que permita interactuar con redes sociales, es posible que, aunque los usuarios no utilicen el servicio, éste recoja datos de tráfico web relativos a las páginas en las que estén instalados.</p>
<p><b>Facebook Ads</b></p>
<p>En LuzIA utilizamos Facebook ads, la plataforma de publicidad de Facebook, que nos permite crear campañas y anuncios. Al generar un anuncio, se puede segmentar el público por:</p>
<ul>
<li style={{ 'list-style-type': 'disc' }} >Lugar</li>
<li style={{ 'list-style-type': 'disc' }}>Datos demográficos (edad, sexo, etc.)</li>
<li style={{ 'list-style-type': 'disc' }}>Intereses (actividades, aficiones, etc.)</li>
<li style={{ 'list-style-type': 'disc' }}>Lo que compran en internet y por otros canales</li>
<li style={{ 'list-style-type': 'disc' }}>Etc.</li>
</ul>
<p>Los datos obtenidos a través de Facebook ads están sujetos a esta política de privacidad desde el momento en que el usuario deja sus datos en el formulario de esta web para unirse al boletín de suscripciones. En ningún caso se utilizará la información procedente de Facebook con una finalidad diferente.</p>
<h2>Legitimación para el tratamiento de tus datos</h2>
<p>La base legal para el tratamiento de sus datos es: el consentimiento.
</p>
<p>Para contactar o realizar comentarios en esta web se requiere el consentimiento con esta política de privacidad.</p>
<p>La oferta prospectiva o comercial de productos y servicios está basada en el consentimiento que se le solicita, sin que en ningún caso la retirada de este consentimiento condicione la ejecución del contrato de suscripción.</p>
<p><b>Categoría de datos
</b></p>
<p>Las categorías de datos que se tratan son datos identificativos.</p>
<p>No se tratan categorías de datos especialmente protegidos.</p>
<h2>¿Por cuánto tiempo conservaremos tus datos?</h2>
<p>Los datos personales proporcionados se conservarán:</p>
<ul>
<li style={{ 'list-style-type': 'disc' }}>Mientras se mantenga la relación mercantil.</li>
<li style={{ 'list-style-type': 'disc' }}>Hasta que no se solicite su supresión por el interesado.</li>
<li style={{ 'list-style-type': 'disc' }}>Periodo a partir de la última confirmación de interés: 1 año.</li>
</ul>
<h2>¿A qué destinatarios se comunicarán tus datos?</h2>
<p>Muchas herramientas que utilizamos para gestionar tus datos son contratadas de terceros.
</p>
<p>Para prestar servicios estrictamente necesarios para el desarrollo de la actividad, LuzIA, comparte datos con los siguientes prestadores bajo sus correspondientes condiciones de privacidad:</p>
<p><b>Google Analytics y hosting de la app:</b> un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Google Analytics utiliza “cookies”, que son archivos de texto ubicados en tu ordenador, para ayudar a LuzIA a analizar el uso que hacen los usuarios del sitio web. La información que genera la cookie acerca de su uso de LuIA (incluyendo tu dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos.
</p>
<p><b>Hosting de Bases de datos:</b>  Proporcionado por Digital Ocean Inc.  New York, NY 101 6th Ave.  Proporcionan el almacenamiento en la nube, pero no gestionan directamente las bases de datos.
</p>
<h2>Navegación</h2>
<p>Al navegar por LuzIA se pueden recoger datos no identificables, que pueden incluir direcciones IP, ubicación geográfica (aproximadamente), un registro de cómo se utilizan los servicios y sitios, y otros datos que no pueden ser utilizados para identificar al usuario. Entre los datos no identificativos están también los relacionados a tus hábitos de navegación a través de servicios de terceros. Esta web utiliza los siguientes servicios de análisis de terceros:
</p>
<ul>
<li style={{ 'list-style-type': 'disc' }}>Google analytics</li>
<li style={{ 'list-style-type': 'disc' }}>Facebook inside</li>
</ul>
<p>Utilizamos esta información para analizar tendencias, administrar el sitio, rastrear los movimientos de los usuarios alrededor del sitio y para recopilar información demográfica sobre nuestra base de usuarios en su conjunto.</p>
<h2>Secreto y seguridad de los datos
</h2>
<p>LuzIA se compromete en el uso y tratamiento de los datos incluidos personales de los usuarios, respetando su confidencialidad y a utilizarlos de acuerdo con la finalidad del mismo, así como a dar cumplimiento a su obligación de guardarlos y adaptar todas las medidas para evitar la alteración, pérdida, tratamiento o acceso no autorizado, de conformidad con lo establecido en la normativa vigente de protección de datos.</p>
<p>Esta web incluye un certificado SSL. Se trata de un protocolo de seguridad que hace que tus datos viajen de manera íntegra y segura, es decir, la transmisión de los datos entre un servidor y usuario web, y en retroalimentación, es totalmente cifrada o encriptada.</p>
<p>LuzIA no puede garantizar la absoluta inexpugnabilidad de la red Internet y por tanto la violación de los datos mediante accesos fraudulentos a ellos por parte de terceros.</p>
<p>Con respecto a la confidencialidad del procesamiento, Luzia Sistemas Conversacionales SL se asegurará de que cualquier persona que esté autorizada por LuzIA para procesar los datos del cliente (incluido su personal, colaboradores y prestadores), estará bajo la obligación apropiada de confidencialidad (ya sea un deber contractual o legal).
</p>
<p>Cuando se presente algún incidente de seguridad, al darse cuenta Luzia Sistemas Conversacionales SL deberá notificar al Cliente sin demoras indebidas y deberá proporcionar información oportuna relacionada con el Incidente de Seguridad tal como se conozca o cuando el Cliente lo solicite razonablemente.</p>
<h2>Exactitud y veracidad de los datos</h2>
<p>Como usuario, eres el único responsable de la veracidad y corrección de los datos que remitas a LuzIA exonerando a Luzia Sistemas Conversacionales SL (LuzIA), de cualquier responsabilidad al respecto.</p>
<p>Los usuarios garantizan y responden, en cualquier caso, de la exactitud, vigencia y autenticidad de los datos personales facilitados, y se comprometen a mantenerlos debidamente actualizados. El usuario acepta proporcionar información completa y correcta en el formulario de contacto o suscripción.</p>
<h2>Aceptación y consentimiento</h2>
<p>El usuario declara haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptando y consintiendo el tratamiento de los mismos por parte de Luzia Sistemas Conversacionales SL (LuzIA) en la forma y para las finalidades indicadas en esta política de privacidad.
</p>
<h2>Revocabilidad</h2>
<p>El consentimiento prestado, tanto para el tratamiento como para la cesión de los datos de los interesados, es revocable en cualquier momento comunicándolo a Luzia Sistemas Conversacionales SL (LuzIA) en los términos establecidos en esta Política para el ejercicio de los derechos ARCO. Esta revocación en ningún caso tendrá carácter retroactivo.</p>
<h2>Cambios en la política de privacidad
</h2>
<p>Luzia Sistemas Conversacionales SL. se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria. En dichos supuestos, Luzia Sistemas Conversacionales SL anunciará en esta página los cambios introducidos con razonable antelación a su puesta en práctica.
</p>
<h2>Correos comerciales</h2>
<p>De acuerdo con la LSSICE, LuzIA no realiza prácticas de SPAM, por lo que no envía correos comerciales por vía electrónica que no hayan sido previamente solicitados o autorizados por el usuario. En consecuencia, en cada uno de los formularios habidos en la web, el usuario tiene la posibilidad de dar su consentimiento expreso para recibir el boletín, con independencia de la información comercial puntualmente solicitada.</p>
<p>Conforme a lo dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y de comercio electrónico, LuzIA se compromete a no enviar comunicaciones de carácter comercial sin identificarlas debidamente.</p>          
<p>Documento revisado el 13-07-2020.</p>    
<p>De parte del equipo que formamos LuzIA te agradecemos el tiempo dedicado en leer esta política de privacidad.
</p>      
          </Privacy> 

          <ConditionWrapper id="topBar">
            {innerWidth > 1100 ? <Footer /> : <div />}
          </ConditionWrapper>
        </AppWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
